<script setup lang='ts'>
import classes from "./classes"
import { Icon } from "../"
import { router } from "app/providers"
import { computed } from "vue"

interface Props {
  type?: string, // a | router-link
  name: string,
  width: string | number,
  height: string | number,
  url?: string | { name: string }, // for router-link
  text?: string,
  count?: number,
  className?: string,
  ariaLabel?: string,
  href?: string, // for a
  target?: string, // for a
}

const props = withDefaults(
  defineProps<Props>(),
  {
    type: "router-link",
    name: "",
    width: "",
    height: "",
    url: "", // for router-link
    text: "",
    count: 0,
    className: "",
    ariaLabel: "",
    href: "", // for link
    target: "" // for link
  }
)

const finalHref = computed(() => {
  if (props.type === "router-link") {
    if (typeof props.url === "object" && props.url.name) {
      const route = router.resolve({
        name: props.url.name
      })

      return route.href
    }

    return props.url
  }

  return props.href
})
</script>

<template>
  <component
    :is="type"
    :class="`${text ? classes.link : 'group'}`"
    :to="url ? url : null"
    :href="finalHref ? finalHref : null"
    :target="target ? target : null"
    :aria-label="ariaLabel ? ariaLabel : null"
  >
    <div :class="classes.iconWrapper">
      <Icon
        :name="name"
        :width="width"
        :height="height"
        :class-name="`${classes.icon} ${className}`"
      />
      <span
        v-if="count"
        :class="classes.count"
      >{{ count }}</span>
    </div>
    <p
      v-if="text"
      :class="classes.text"
    >
      {{ text }}
    </p>
  </component>
</template>
