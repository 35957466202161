<script setup lang="ts">
import classes from "./classes"
import { IconButton } from "shared/ui"
import { ROUTE_NAMES } from "shared/constants"
import { useBreakpoints } from "@vueuse/core"
import { useCartStore, useUserStore } from "stores"
import { YANDEX_METRIKA_GOALS, emitYandexMetrika } from "shared/lib"

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const cartStore = useCartStore()
const userStore = useUserStore()

const emit = defineEmits<{
  handleClick: [payload: string];
}>()

const clickSearch = () => {
  emit("handleClick", "")
}
</script>

<template>
  <div :class="classes.wrapper">
    <IconButton
      :url="{ name: ROUTE_NAMES.FAVOURITE }"
      name="common/favourite"
      :width="xlAndLarger ? 28 : 20"
      :height="xlAndLarger ? 28 : 20"
      :count="userStore.favouriteDevices.length ?? undefined"
      class="hidden xl:grid"
      aria-label="Избранное"
    />
    <IconButton
      :url="{ name: ROUTE_NAMES.COMPARISON }"
      :width="xlAndLarger ? 28 : 20"
      :height="xlAndLarger ? 28 : 20"
      name="common/comparison"
      :count="userStore.comparableDevices.length ?? undefined"
      class="hidden xl:grid"
      aria-label="Сравнение товаров"
    />
    <IconButton
      url=""
      name="common/search"
      width="20"
      height="20"
      class="xl:hidden"
      aria-label="Поиск"
      @click="clickSearch"
    />
    <IconButton
      type="a"
      href="https://lk.pedant.market/"
      target="_blank"
      :width="xlAndLarger ? 28 : 20"
      :height="xlAndLarger ? 28 : 20"
      name="common/person"
      aria-label="Личный кабинет"
    />
    <IconButton
      :url="{ name: ROUTE_NAMES.CART }"
      :width="xlAndLarger ? 28 : 20"
      :height="xlAndLarger ? 28 : 20"
      name="common/cart-outline"
      :count="cartStore.device ? 1 : undefined"
      aria-label="Корзина"
      @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_IN_HEADER)"
    />
  </div>
</template>
